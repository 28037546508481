import { useEffect } from "react"
import { navigate, graphql } from "gatsby"

const DailyVideoPage = ({ data }: any) => {
  const todayVideo = data.allContentfulDailyVideo.edges[0]

  useEffect(() => {
    todayVideo
      ? navigate(`${todayVideo.node.displayDate}/${todayVideo.node.video.slug}`)
      : navigate("/404")
  })

  return null
}

export const query = graphql`
  query DailyVideoPageQuery($today: Date) {
    allContentfulDailyVideo(
      # grabs all videos that have a date less than or equal to today
      # also makes sure today's date is greater than the expiration date (or is not expired)
      # sort to the most recent date (should be today) and limit to our one video to not over fetch
      filter: { displayDate: { lte: $today }, expirationDate: { gte: $today } }
      sort: { fields: displayDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          displayDate(formatString: "YYYY/MM/DD")
          video {
            slug
          }
        }
      }
    }
  }
`

export default DailyVideoPage
